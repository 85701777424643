import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  InputSignal,
  OutputEmitterRef,
  TemplateRef,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import { DatePipesModule } from '@core/shared/util';

import { ExportItem } from '../../models';

@Component({
  selector: 'mpcm-export-items',
  standalone: true,
  templateUrl: './export-items.component.html',
  styleUrl: './export-items.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, MatTableModule, MatButtonModule, MatIconModule, DatePipesModule],
})
export class ExportItemsComponent {
  readonly exportItems: InputSignal<ExportItem[]> = input.required<ExportItem[]>();

  readonly itemDelete: OutputEmitterRef<string> = output<string>();

  protected readonly columns: string[] = ['export', 'user', 'date', 'actions'];

  @ContentChild(TemplateRef)
  itemInfoTemplate!: TemplateRef<unknown>;

  deleteExportItem(articleExportId: string): void {
    this.itemDelete.emit(articleExportId);
  }

  trackByFn(_index: number, item: ExportItem): string {
    return item.articleExportId;
  }
}
